












































































































































import { Component, Vue } from 'vue-property-decorator'
import MediaList from '@/components/MediaList/MediaList.vue'
import { IMediaListItem } from '@/components/MediaList/lib/MediaList'
import { sensorsStore } from '@/data/sensor/lib/sensorsStore'
import { SensorObject, SensorsHandler } from '@/data/sensor'
import moment from 'moment'
import { Vehicle, VehicleObject } from '@/data/fleet'
import { ResponseError } from '@/data/error'
import { authenticationStore } from '@/store/modules/authenticationStore'

@Component({
  components: { MediaList }
})
export default class SensorsCard extends Vue {
  private sensorsHandler: SensorsHandler = new SensorsHandler()
  public showDeleteConfirmation = false
  public selectedSensor: SensorObject | null = null
  public isLoadingSensors = true
  public isLoadingVehicles = false
  public showAddVehicleModal = false
  public edit: number | null = null
  public editSensor: SensorObject | null = null
  public addVehicle = {
    vehicle: null as Vehicle | null,
    position: ''
  }

  get isAdmin (): boolean | null {
    return authenticationStore.isAdministrator
  }

  get sensorItems (): Array<SensorObject> {
    return sensorsStore.sensors
  }

  get vehiclesItems (): Array<IMediaListItem> {
    const mediaItems: Array<IMediaListItem> = []
    this.sensorsHandler.tempVehicles.forEach(vehicle => {
      mediaItems.push({
        key: vehicle.key,
        title: `${vehicle.make} ${vehicle.model}`,
        subtitle: vehicle.plateNumber,
        icon: 'truck',
        iconSize: '3.2rem',
        variant: 'primary',
        imageSrc: vehicle.imageSrc ?? undefined
      })
    })
    return mediaItems
  }

  public fields = [{
    key: 'avatar',
    thStyle: 'width:48px;',
    sortable: false
  }, {
    key: 'model',
    sortable: false
  }, {
    key: 'name',
    sortable: false
  }, {
    key: 'vehicle',
    sortable: false,
    formatter: (value: VehicleObject | undefined): string => {
      return this.formattedVehicle(value)
    }
  }, {
    key: 'position',
    sortable: false
  }, {
    key: 'created',
    sortable: false,
    formatter: (value: number): string => {
      return moment(value).format('DD.MM.YYYY')
    }
  }, {
    key: 'actions',
    sortable: false
  }]

  public formattedVehicle (vehicle?: VehicleObject): string {
    if (vehicle) {
      return `${vehicle.make} ${vehicle.model} (${vehicle.plateNumber})`
    }
    return ''
  }

  public onDeleteConfirm (): void {
    if (this.selectedSensor) {
      this.isLoadingSensors = true
      SensorsHandler.removeSensor(this.selectedSensor).then(() => {
        this.isLoadingSensors = false
      }).catch(error => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.removeSensor.title'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.$root.$emit('bv::refresh::table', 'table-sensors')
      })
    }
    this.showDeleteConfirmation = false
    this.selectedSensor = null
  }

  public onDeleteCancel (): void {
    this.showDeleteConfirmation = false
    this.selectedSensor = null
  }

  public onDeleteSensor (item: SensorObject): void {
    this.showDeleteConfirmation = true
    this.selectedSensor = item
  }

  /**
   * Set the `selectedSensor` item and show the add vehicle modal.
   *
   * @param item The sensor item to be selected.
   */
  public onAddVehicleToSensor (item: SensorObject): void {
    this.selectedSensor = item
    this.$root.$emit('bv::show::modal', 'add-vehicle-modal')
  }

  public onSelectedVehicle (key: string): void {
    const index = this.sensorsHandler.tempVehicles.map(v => v.key).indexOf(key)
    const indexMediaItems = this.vehiclesItems.map(c => c.key).indexOf(key)
    this.vehiclesItems.map(el => { el.isSelected = false })
    if (index > -1 && indexMediaItems > -1) {
      this.addVehicle.vehicle = this.sensorsHandler.tempVehicles[index]
      this.vehiclesItems[indexMediaItems].isSelected = true
    }
  }

  public onAddVehicleConfirm (): void {
    this.isLoadingSensors = true
    // TODO: Enable possibility to select items from MediaList
    if (this.selectedSensor && this.addVehicle.vehicle) {
      SensorsHandler.addVehicleToSensor(
        this.selectedSensor,
        this.addVehicle.vehicle,
        this.addVehicle.position
      ).then(() => {
        this.isLoadingSensors = false
      }).catch((error) => {
        this.isLoadingSensors = false
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.error'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.$root.$emit('bv::refresh::table', 'table-sensors')
      })
    }
  }

  public onAddVehicleModalShown (): void {
    this.isLoadingVehicles = true
    this.sensorsHandler.loadVehiclesOnce().catch((error) => {
      if (error.code) {
        error.name = error.code
      }
      const responseError = new ResponseError(error.name, error.message)
      const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
      this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
        title: this.$tc('databaseResults.error'),
        autoHideDelay: 15000,
        appendToast: true,
        solid: true,
        variant: 'danger'
      })
    }).finally(() => {
      this.isLoadingVehicles = false
    })
  }

  public onAddVehicleModalHidden (): void {
    this.selectedSensor = null
    this.addVehicle = {
      vehicle: null,
      position: ''
    }
  }

  public onDeleteVehicleFromSensor (item: SensorObject): void {
    if (item.vehicle) {
      this.isLoadingSensors = true
      SensorsHandler.removeVehicleFromSensor(item.key, item.vehicle.key).then(() => {
        this.isLoadingSensors = false
      }).catch((error) => {
        this.isLoadingSensors = false
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.error'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.$root.$emit('bv::refresh::table', 'table-sensors')
      })
    }
  }

  public onEditSensorPosition (item: SensorObject, index: number): void {
    this.edit = index
    this.editSensor = { ...item }
  }

  public onEditPositionSave (): void {
    if (this.editSensor && this.editSensor.vehicle) {
      SensorsHandler.addVehicleToSensor(
        this.editSensor,
        this.editSensor.vehicle,
        this.editSensor.position
      ).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.error'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.edit = null
        this.editSensor = null
        this.$root.$emit('bv::refresh::table', 'table-sensors')
      })
    }
  }

  public onEditPositionCancel (): void {
    this.edit = null
    this.editSensor = null
  }

  public mounted (): void {
    sensorsStore.registerComponent(this.$options.name)
    // this.sensorsHandler.startSensorListener()
    this.isLoadingSensors = false
  }

  public beforeDestroy (): void {
    sensorsStore.unregisterComponent(this.$options.name)
  }
}
