

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CardSkeleton extends Vue {
  @Prop(Boolean) showRestrictedOverlay!: boolean | null
}
