






































import { Component, Vue } from 'vue-property-decorator'
import FormCheck from '@/common/formCheck'
import { SensorsHandler } from '@/data/sensor'
import { ResponseError } from '@/data/error'

@Component
export default class AddSensorForm extends Vue {
  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public responseError: string | null = null
  public showResponseAlert = false
  public serialKey = ''

  get stateSerialKey (): false | null {
    return this.formCheck.findFormError('serialKey') ? false : null
  }

  /**
   * Submit the add sensor form.
   */
  public submitAddSensor (): void {
    this.isLoading = true
    if (!this.checkForm()) {
      SensorsHandler.registerSensor(this.serialKey).then(() => {
        this.resetForm()
      }).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        this.responseError = responseError.getDatabaseErrorLocaleKey()
        this.showResponseAlert = true
      })
    }
    this.isLoading = false
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('serialKey', this.serialKey)
    this.formCheck.checkSerialKey('serialKey', this.serialKey)
    return this.formCheck.hasErrors()
  }

  /**
   * Format input value to be upper case.
   */
  public UpperCaseFormatter (value: string): string {
    return value.toUpperCase()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.serialKey = ''
    this.formCheck.clearFormErrors()
    this.showResponseAlert = false
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }
}
