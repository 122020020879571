













import { Component, Vue } from 'vue-property-decorator'
import AddSensorForm from '@/components/sensors/AddSensorForm.vue'
import CardSkeleton from '@/components/skeletons/CardSkeleton.vue'
import { authenticationStore } from '@/store/modules/authenticationStore'

@Component({
  components: { AddSensorForm, CardSkeleton }
})
export default class AddSensorCard extends Vue {
  get isAdmin (): boolean | null {
    return authenticationStore.isAdministrator
  }
}
