















import { Component, Vue } from 'vue-property-decorator'
import MediaListSkeleton from '@/components/skeletons/MediaListSkeleton.vue'
import MediaList from '@/components/MediaList/MediaList.vue'
import { IMediaListItem } from '@/components/MediaList/lib/MediaList'
import { authenticationStore } from '@/store/modules/authenticationStore'

@Component({
  components: { MediaListSkeleton, MediaList }
})
export default class SensorsSellingCard extends Vue {
  get isAdmin (): boolean | null {
    return authenticationStore.isAdministrator
  }

  get availableSensorsMediaItems (): Array<IMediaListItem> {
    return [{
      key: '01',
      localeTitle: 'sales.sensors.bluePuckT.title',
      localeSubtitle: 'sales.sensors.bluePuckT.description',
      localeCaption: 'sales.sensors.bluePuckT.price',
      iconSize: '3rem',
      isClickable: false
    }, {
      key: '02',
      localeTitle: 'sales.sensors.bluePuckTEN12830.title',
      localeSubtitle: 'sales.sensors.bluePuckTEN12830.description',
      localeCaption: 'sales.sensors.bluePuckTEN12830.price',
      iconSize: '3rem',
      isClickable: false
    }]
  }
}
