import constants from '@/common/constants'
import { FirebaseAppInstance } from '@/data/app'
import {
  DatabaseReference, DataSnapshot,
  getDatabase, ref, off,
  onChildAdded, onChildChanged, onChildRemoved
} from 'firebase/database'
import { SensorObject } from '..'
import { sensorsStore } from './sensorsStore'

export class SensorsInstance {
  private static instance: SensorsInstance
  private sensorsRef: DatabaseReference
  public isListeningForSensors = false

  constructor () {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    this.sensorsRef = ref(db, constants.DB_SENSORS)
  }

  public static getInstance (): SensorsInstance {
    if (!SensorsInstance.instance) {
      SensorsInstance.instance = new SensorsInstance()
    }
    return SensorsInstance.instance
  }

  /**
   * Start listening for changes of sensors in the database.
   *
   * This includes listening on the `childAdded`, `childChanged` and
   * `childRemoved` -events. The sensors are then accessable by using the
   * `sensors`-getter in the `sensorsStore`.
   *
   * To stop listening for these events use the `stopListeningForSensors`
   * method.
   */
  public startListeningForSensors (): void {
    this.stopListeningForSensors()
    this.isListeningForSensors = true

    // Child added
    onChildAdded(this.sensorsRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        sensorsStore.add(this.convertSnapshotToSensor(snapshot, snapshot.key))
      }
    })

    // Child changed
    onChildChanged(this.sensorsRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        const newSensor = this.convertSnapshotToSensor(snapshot, snapshot.key)
        sensorsStore.update(newSensor)
      }
    })

    // Child removed
    onChildRemoved(this.sensorsRef, (snapshot) => {
      sensorsStore.remove(snapshot.key)
    })
  }

  /**
   * Stop listening for changes of sensors in the database.
   */
  public stopListeningForSensors (): void {
    off(this.sensorsRef)
    sensorsStore.clearData()
    this.isListeningForSensors = false
  }

  /**
   * Convert a firebase `DataSnapshot` to a SensorObject.
   *
   * @param snapshot The firebase snapshot.
   * @param key The key of the snapshot.
   * @returns The converted SensorObject.
   */
  private convertSnapshotToSensor (snapshot: DataSnapshot, key: string): SensorObject {
    // const snapshortCustomers = snapshot.val().customers as Record<string, boolean>
    // const categoryCustomers = this.convertObjectCustomersToArray(snapshortCustomers)

    const sensor = snapshot.val() as SensorObject
    sensor.key = key
    return sensor
  }

  // private convertObjectCustomersToArray (objectCustomers: Record<string, boolean>): Array<CategoryCustomer> {
  //   const categoryCustomers: Array<CategoryCustomer> = []
  //   if (objectCustomers) {
  //     for (const index in objectCustomers) {
  //       const categoryCustomer: CategoryCustomer = {
  //         key: index,
  //         isCustomer: objectCustomers[index]
  //       }
  //       categoryCustomers.push(categoryCustomer)
  //     }
  //   }
  //   return categoryCustomers
  // }
}
