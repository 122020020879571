


















import { Component, Vue } from 'vue-property-decorator'
import SensorsCard from '@/components/sensors/SensorsCard.vue'
import AddSensorCard from '@/components/sensors/AddSensorCard.vue'
import SensorsSellingCard from '@/components/sensors/SensorsSellingCard.vue'

@Component({
  components: { SensorsCard, AddSensorCard, SensorsSellingCard }
})
export default class SensorsVueComponent extends Vue { }
