import constants from '@/common/constants'
import {
  getDatabase, ref, child,
  get, push, update, remove,
  serverTimestamp, increment
} from 'firebase/database'
import { FirebaseAppInstance } from '@/data/app'
import { SensorObject } from './sensor'
import { VehicleObject } from '@/data/fleet'
import { ResponseError } from '@/data/error'
import { sensorsStore } from './sensorsStore'

interface DbAddSensor {
  // eslint-disable-next-line
  created: any
  datalogger: boolean
  firmware: string
  logInterval: number
  model: string
  name: string
  password: string
  serialKey: string
}

export class SensorsHandler {
  public tempVehicles: Array<VehicleObject>

  constructor () {
    this.tempVehicles = []
  }

  /**
   * Update custom properties of a sensor.
   *
   * This will also add or update the `modified` property.
   * @param key The database key.
   * @param data The data dictionary-object containing all changes.
   * @returns Promise
   */
  // eslint-disable-next-line
  public static async updateSensorProperties (key: string, data: Record<string, any>): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const sensorsRef = ref(db, constants.DB_SENSORS)

    await update(child(sensorsRef, key), {
      ...data,
      modified: serverTimestamp()
    })
    return Promise.resolve()
  }

  /**
   * Remove a sensor.
   * @param sensor The sensor that should be removed.
   */
  public static async removeSensor (sensor: SensorObject): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const sensorsRef = ref(db, constants.DB_SENSORS)
    const vehiclesRef = ref(db, constants.DB_VEHICLES)

    if (sensor.vehicle) {
      await remove(child(child(child(vehiclesRef, sensor.vehicle.key), constants.DB_SENSORS), sensor.key))
    }
    await remove(child(sensorsRef, sensor.key))
    Promise.resolve()
  }

  public static async registerSensor (serialKey: string): Promise<void> {
    if (this.checkSensorAlreadyExists(serialKey)) {
      return Promise.reject(new ResponseError('sensor-already-registered', 'Already registered'))
    }
    const sensor = await this.loadSensorWithKey(serialKey)
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const addSensorRef = ref(db)
    const newPostKey = push(child(addSensorRef, constants.DB_SENSORS)).key

    const createSensor: DbAddSensor = {
      created: serverTimestamp(),
      datalogger: sensor.datalogger,
      firmware: sensor.firmware,
      logInterval: sensor.logInterval,
      model: sensor.model,
      name: sensor.name,
      password: sensor.password,
      serialKey: sensor.serialKey
    }

    // eslint-disable-next-line
    const updates: Record<string, any> = {}
    updates[`${constants.DB_SENSORS}/${newPostKey}`] = createSensor
    updates[`${constants.DB_STATISTICS}/${constants.DB_SENSORS}/count`] = increment(1)

    await update(addSensorRef, updates)
    return Promise.resolve()
  }

  public static async loadSensorWithKey (serialKey: string): Promise<SensorObject> {
    const appDefault = FirebaseAppInstance.getInstance().getApp()
    const dbDefault = getDatabase(appDefault)
    const defaultSensorRef = ref(dbDefault, constants.DB_MGMT_SENSORS)

    const sensor = await get(child(defaultSensorRef, serialKey)).then((snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        const sensor = snapshot.val() as SensorObject
        sensor.serialKey = snapshot.key
        sensor.key = snapshot.key
        return sensor
      }
      return undefined
    })

    if (sensor) {
      return Promise.resolve(sensor)
    }
    return Promise.reject(new ResponseError('serial-key-not-found', 'Not found'))
  }

  public async loadVehiclesOnce (): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const vehiclesRef = ref(db, constants.DB_VEHICLES)

    this.tempVehicles = []
    await get(vehiclesRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        snapshot.forEach(childSnapshot => {
          if (childSnapshot.exists() && childSnapshot.key) {
            const vehicle = childSnapshot.val() as VehicleObject
            vehicle.key = childSnapshot.key
            this.tempVehicles.push(vehicle)
          }
        })
      }
    })
  }

  /**
   * Assign a sensor to an existing vehicle.
   * @param sensor The sensor to be updated.
   * @param vehicle The vehicle to be added.
   * @param position The position of the sensor within the vehicle.
   * @returns Promise
   */
  public static async addVehicleToSensor (
    sensor: SensorObject,
    vehicle: VehicleObject,
    position?: string
  ): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const vehiclesRef = ref(db, constants.DB_VEHICLES)

    const vehicleForSensor = {
      plateNumber: vehicle.plateNumber,
      key: vehicle.key,
      make: vehicle.make,
      model: vehicle.model
    }

    const sensorForVehicle = {
      datalogger: sensor.datalogger,
      firmware: sensor.firmware,
      logInterval: sensor.logInterval,
      model: sensor.model,
      name: sensor.name,
      password: sensor.password,
      position: position ?? null
    }
    await this.updateSensorProperties(sensor.key, { vehicle: vehicleForSensor, position: position ?? null })

    // eslint-disable-next-line
    const updates: Record<string, any> = {}
    updates[`${vehicle.key}/modified`] = serverTimestamp()
    updates[`${vehicle.key}/${constants.DB_SENSORS}/${sensor.key}`] = sensorForVehicle
    await update(vehiclesRef, updates)

    return Promise.resolve()
  }

  public static async removeVehicleFromSensor (sensorKey: string, vehicleKey: string): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const vehiclesRef = ref(db, constants.DB_VEHICLES)

    // eslint-disable-next-line
    const updates: Record<string, any> = {}
    updates[`${vehicleKey}/modified`] = serverTimestamp()
    updates[`${vehicleKey}/${constants.DB_SENSORS}/${sensorKey}`] = null
    await update(vehiclesRef, updates)

    await SensorsHandler.updateSensorProperties(sensorKey, { vehicle: null, position: null })
    return Promise.resolve()
  }

  public static checkSensorAlreadyExists (serialKey: string): boolean {
    const index = sensorsStore.sensors.map(sensor => sensor.serialKey).indexOf(serialKey)
    return index > -1
  }
}
