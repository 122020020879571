import { Module } from 'vuex-class-modules'
import store from '@/store'
import { SensorsInstance, SensorObject } from '..'
import { DataStore } from '@/store/lib/dataStore'

@Module
export class SensorsStore extends DataStore<SensorObject> {
  get sensors (): Array<SensorObject> {
    return this.data
  }

  protected startListening (): void {
    if (!this.isRestricted) {
      const sensorsInstance = SensorsInstance.getInstance()
      if (!sensorsInstance.isListeningForSensors) {
        sensorsInstance.startListeningForSensors()
      }
    } else {
      this.stopListening()
    }
  }

  protected stopListening (): void {
    const sensorsInstance = SensorsInstance.getInstance()
    sensorsInstance.stopListeningForSensors()
  }
}

export const sensorsStore = new SensorsStore({ store: store, name: 'sensors' })
